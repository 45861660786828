.hero {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(8, 0, 58, 0.5), rgba(8, 0, 58, 0.5)), 
    url('../../assets/ssa/background.png') no-repeat center center;
    background-size: cover;
    background-position: center top;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}


.hero-text {
    text-align: center;
    max-width: 800px;
}
.hero-text h1 {
    font-size: 60px;
    font-weight: 600;
}
.hero-text p{
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

.hero-text h1 {
    background: linear-gradient(to right, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
    font-weight: 1000; /* Increase font weight for better visibility */
    -webkit-text-stroke: 0.9px black; /* Thinner stroke to prevent overlap */
}





  

/* ----- media query ---------- */

@media (max-width: 850px){
    .hero-text h1{
        font-size: 40px;
    }

}

@media (max-width: 650px){
    .hero-text h1{
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }
    .hero-text p{
        font-size: 14px;
        margin: 15px auto 30px;
    }

}