


nav { 
    width: 100%;
    /* background: #333; */
    color: #fff;
    padding: 5px 0; /* Reduced padding for a thinner navbar */
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.logo {
    width: 80px; /* Slightly smaller logo to match the thinner navbar */
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 0 15px; /* Reduced margin for closer spacing between items */
    font-size: 16px;
    letter-spacing: 0.5px; /* Reduces letter spacing */
}

/* .dark-nav{
    background: #212ea0;
    transition: 0.5s;
    
} */

.menu-icon{
    display: none;
}

.logo {
    cursor: pointer;
    transition: transform 0.3s ease, translate 0.3s ease;
}

.large-logo {
    transform: scale(3.5) translateY(30px); /* Adjust the translateY value as needed */
    position: relative;
    z-index: 10;
}



.dark-nav {
    background: linear-gradient(to right, red, blue);
}




/* ----- media query ---------- */

@media (max-width: 1000px) {
   .logo{ 
    width: 75px;

   }
   
   nav ul li{
    margin: 5px 10px;
   } 
}

@media (max-width: 840px){
    nav{
        padding: 8px 0;

    }
    nav ul{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: #212ea0;
        z-index: -1;
        width: 215px;
        padding-top: 70px;
        transition: 0.5s;
    }
    nav ul li{
        display: block;
        margin: 25px 40px;
    }
    .menu-icon{
        display: block;
        width: 30px;
        cursor: pointer;
    }
    .hide-mobile-menu{
        right: -215px;
    }
}